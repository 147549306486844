// Here you can add other styles
// Here you can add other styles
.btn-upload-wrapper {
    position: relative;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
.btn-upload-wrapper input[type=file] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}
.video-player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
   
.video-react-player {
    position: absolute;
    top: 0;
    left: 0;
}